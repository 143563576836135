.card{ 
    height: 100vh;
    margin: 24px;
    padding: 25px;
    width: 100px;
}
.center{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.btn{ 
   background: goldenrod;
   color: white;
}

.center .btn{ 
   font-size: 14px !important;
   margin-top: 20px !important;
   font-weight: 400;
   padding: 12px 72px;
   border-radius: 3px !important;
 } 
 .cart-Image{  
     width: 100%;
     height: 200px;
 }
 .checkout__Add{
    width: 100%;
    margin-bottom: 10px;
    height: 150px;
 }
 .checkout_title{
margin-right: 10px;
padding: 10px;
border-bottom: 1px solid lightgray;
 }
 .checkout{
    display: flex;
    padding: 20px;
    background-color: white;
    height: max-content;
 }
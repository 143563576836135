.Customer-service{ 
        display: flex; 
        justify-content: center;
      position: relative;
      bottom: 40px;
        
}
    .Image{ 
    height: 50px;
    width: 150px;
    margin-top: 50px;
    position: relative;
    left: 80px;
    
   }
   .Content-Box{ 
     display:flex;
     flex-direction: column;
     justify-content: center;
     width: 150%;
     height: 65%;
     border: 1px solid rgb(93, 95, 93);
     border-radius: 5px;
     justify-items: center;
     
   }
   .Text-label{ 
     margin-left: 10px;
     margin-right: 10px;
    height: 30px;
    box-shadow: thistle;
   } 
   .sign{ 
     font-size: 30px;
     font-family: sans-serif;
     font-weight: 400;
    
   }  
     .Text{ 
      margin-left: 10px;
      font-weight: 600;
        }
        .btn{
          background: rgb(233, 181, 51);
          height: 40px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          margin-bottom: 10px;
          font-weight: 700;
          font-family: sans-serif;
        }
        .btn:hover{ 
          transition: 1s;
          background: rgba(246, 164, 10, 0.966);
        }
        .paragraph{  
          margin-left: 10px;
          font-size: small;
          text-decoration: none;
        }
        .Create{  
          width: 151%;
          height: 30px;
        } 
        .Icon{    
          width: 15%;
        } 
        .content{    
         display: flex;
         flex-direction: column;
         position: relative;
         bottom: 20px;
        } 
        

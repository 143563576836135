.subtotal{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    border-radius: 3px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
}

.subtotal__gift{
    display: flex;
    align-items: center;

}
.subtotal__gift > input{
    margin-right: 5px;
}
.subtotal > button{
    background-color: #f0c14b;
    border-radius: 2px;
    width: 90%;
    height: 30px;
    border:1px solid;
    cursor: pointer;
    margin-top: 10px;
    padding: 5px;
    color: #111;
    border-color: #a88734 #9c7e31 #846a29;
}
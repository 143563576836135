.product{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 10px;
    padding: 20px;
    width: 100%;
    max-height: 400px;
    min-width: 100px;
    background-color: white;
    z-index: 5;
    border:1px solid gray ;
    border-radius: 10px;
   } 
   
   .product > img{ 
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 15px;
   } 
   .product-image:hover{   
       transform: scale(1.2);
       transition: all 1s linear;
   } 
  
   .product>.addtocart-btn{ 
       padding: 5px; 
       background: #f0c14b;
       border: 1px solid;
       margin-top: 10px;
       border-color: #a88734 #9c7e31 #846a29;
       color: #111;
        }
        
        .addtocart-btn:hover{
            transition: 1s;
            background-color: green;
            cursor: pointer;
            
        }
   .product-price{ 
       position: relative; 
      bottom:10px;
       margin-top: 5px;
    }
    .product-info{  
        height: 100px;
        margin-bottom: 15px;
        text-align: left;
        position: relative;
        bottom: 20px;

     } 
     .description{
         font-family: sans-serif;
         font-weight: 600;
     } 

     .product-rating{ 
         display: flex;
         position: relative;
         bottom: 30px;
     }
    .Prices,.rupees{ 
       color: red;
       font-size: 20px;
    }
    .star{ 
        color: gold;
        font-size: 20px;
       
    }
        
     
    




  
.checkoutProduct{
    display:flex;
    margin-top: 20px;
    margin-bottom: 20px;

}
.checkoutProduct__info{
    padding-left: 20px;
}

.checkoutProduct__info > button{
    background-color: #f0c14b;
    color: #111;
    border-color: #a88734 #9c7e31 #846a29;
    border: 1px solid;
    margin-top: 10px;
    cursor: pointer;
}
.checkoutProduct__image{
    object-fit: contain;
    width: 180px;
    height: 180px;
}
.checkoutProduct__rating{
    display: flex;
}
.checkoutProduct__title{
    font-weight: 800;
    font-size: 17px;
    width: 795px;
}